<template>
  <div>
    <v-container class="my-4">
      <v-row>
        <v-col cols="12">
          <v-breadcrumbs :items="breadcrumbs" large class="pl-0" />
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Ara"
                single-line
                hide-details
              />
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click.stop="
                  () => {
                    dialogContent = 'Add';
                    dialog = true;
                  }
                "
              >
                <v-icon class="mr-2">add_circle</v-icon>
                <span>Modül Ekle</span>
              </v-btn>
              <v-btn icon @click="getItems">
                <v-icon class="mr-2">refresh</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              loading-text="Yükleniyor..."
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu close-on-content-click>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="openItem(item)">
                      <v-list-item-icon>
                        <v-icon>visibility</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Göster</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-icon>
                        <v-icon>create</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Düzenle</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item)">
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Sil</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="600" scrollable>
      <component
        :is="dialogContent"
        @close="dialog = false"
        :lesson="selectedItem"
        :courseId="courseId"
        @refresh="getItems"
      />
    </v-dialog>

    <confirm ref="confirm" />
  </div>
</template>

<script>
import Vue from "vue";
import firebase from "@/plugins/firebase";
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Confirm from "@/components/Confirm.vue";

export default Vue.extend({
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Ad",
          align: "start",
          filterable: true,
          value: "title"
        },
        { text: "Sıra", value: "rowNumber", width: "90px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],
      items: [],
      selectedItem: {},
      dialog: false,
      dialogContent: "",
      course: {}
    };
  },
  computed: {
    courseId() {
      return this.$route.params.id;
    },
    breadcrumbs() {
      return [
        {
          text: "Tüm Eğitimler",
          exact: true,
          to: { name: "admin-courses" }
        },
        {
          text: this.course.name
        },
        {
          text: "Konular"
        }
      ];
    }
  },
  methods: {
    async getItems() {
      this.loading = true;

      try {
        this.items = [];

        // Get all lessons
        const query = await firebase
          .firestore()
          .collection("lessons")
          .where("courseId", "==", this.courseId)
          .orderBy("rowNumber")
          .get();

        query.forEach(doc => {
          if (doc.exists) {
            const lesson = doc.data();
            this.items.push({
              id: doc.id,
              rowNumber: lesson.rowNumber,
              title: lesson.title
            });
          }
        });
      } catch (err) {
        this.$notify({
          type: "error",
          text: `Konu listesi alınamadı.`
        });
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async deleteItem(item) {
      const confirmation = await this.$refs.confirm.open(
        "Modül Silme",
        "Bu işlem geri alınamaz. Devam etmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (confirmation) {
        this.$notify({
          text: "Modül siliniyor. Lütfen bekleyin..."
        });

        try {
          // Modülü sil
          await firebase
            .firestore()
            .collection("lessons")
            .doc(item.id)
            .delete();

          this.$notify({
            type: "success",
            text: "Modül başarıyla silindi."
          });

          this.getItems();
        } catch (err) {
          this.$notify({
            type: "error",
            text: "Modül silinemedi."
          });

          console.log(err);
        }
      }
    },
    async editItem(item) {
      this.selectedItem = item;
      this.dialogContent = "Edit";
      this.dialog = true;
    },
    openItem(item) {
      this.$router.push({
        name: "admin-course-lesson",
        params: {
          id: item.id
        }
      });
    },
    async getCourse() {
      try {
        // Get course
        const result = await firebase
          .firestore()
          .collection("courses")
          .doc(this.courseId)
          .get();

        if (result.exists) {
          this.course = result.data();
          this.course.id = result.id;
        } else {
          this.$notify({
            type: "error",
            text: "Eğitim bulunamadı"
          });
        }
      } catch (err) {
        this.$notify({
          type: "error",
          text: `Eğitim bilgileri alınamadı. ${err.code} ${err.message}`
        });
      }
    }
  },
  mounted() {
    this.getItems();
    this.getCourse();
  },
  components: {
    Add,
    Edit,
    Confirm
  }
});
</script>
