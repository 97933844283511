<template>
  <div id="addLesson">
    <v-card>
      <v-form ref="form" @submit.prevent="add()">
        <v-card-title>Yeni Modül</v-card-title>
        <v-divider />
        <v-card-text>
          <v-text-field
            id="rowNumber"
            v-model="form.rowNumber"
            label="Sıra No"
            name="rowNumber"
            type="number"
            required
            :rules="rowNumberRules"
          />
          <v-text-field
            id="title"
            v-model="form.title"
            label="Modül Adı"
            name="title"
            type="text"
            required
            :rules="titleRules"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">İptal Et</v-btn>
          <v-btn color="primary" type="submit" text :disabled="submitDisabled"
            >Ekle</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  props: {
    courseId: String,
    lesson: Object
  },
  data: () => ({
    form: {
      rowNumber: 1,
      title: ""
    },
    submitDisabled: false
  }),
  computed: {
    rowNumberRules() {
      return [v => !!v || "Lütfen sıra numarası belirtiniz"];
    },
    titleRules() {
      return [v => !!v || "Lütfen modülün adını yazınız"];
    }
  },
  methods: {
    close() {
      this.reset();
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async add() {
      if (this.validate()) {
        this.submitDisabled = true;
        this.$notify({
          text: "Modül ekleniyor. Lütfen bekleyin..."
        });

        try {
          const newLesson = {
            rowNumber: this.form.rowNumber,
            title: this.form.title,
            courseId: this.courseId
          };

          // Add course to database
          await firebase
            .firestore()
            .collection("lessons")
            .add(newLesson);

          this.refresh();
          this.close();

          this.$notify({
            type: "success",
            text: "Eğitim başarıyla eklendi."
          });
        } catch (err) {
          this.$notify({
            type: "error",
            text: "Eğitim eklenemedi."
          });

          console.log(err);
        } finally {
          this.submitDisabled = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
#addLesson {
  .ck-content {
    min-height: 100px;
  }
}
</style>
